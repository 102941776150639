import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth.js";
import { instance2 as axiosAuth_live } from "@/api/axios-auth_live";
import { instance as axiosAuth } from "@/api/axios-auth";

Vue.use(Vuex);

// static adress of media_files CDN
const CDN_URL = "https://respeakmedia.azureedge.net";
const CDN_URL_ICE = "https://assets.filme-serien.iceportal.de/contents/tatort";

export default new Vuex.Store({
  state: {
    ice_mode: false,
    client_config: {
      accent_color: null,
      logo_src: null,
      avatar_src: null,
      avatar_name: null,
      video_messages: null,
      audio_messages: null,
      background_image: null,
    },
    survey_id_state: null,
    survey_id_int: null,
    survey_name: null,
    game_is_finished: false,
    avatar_name: null,
    background_image: null,
    speed: 0.5,
    feedback_allowed: null,
    training_mode: null,
    messenger_ui: null,
    time_period: "all",
    // current survey for global permissions access
    survey_data: null,
    // switch for autoplay mode
    audioAutoplay: true,
    // switch for playing notification sounds
    notifications_sounds: false,
    // are there unread messages in multichat?
    unread_messages: false,
    // media list for containing locations for one or multiple dialog media data
    media_files: [],
    // dialog blocks for current survey
    dialog_blocks: [],
    //dialog to display when errors occur
    error_handler: {
      show: false,
      title: "",
      msg: "",
    },
  },

  getters: {
    getClientStyle(state) {
      return state.client_config;
    },
    getMessenger_ui(state) {
      return state.messenger_ui;
    },
  },

  mutations: {
    set_ice_mode(state, mode) {
      state.ice_mode = mode;
    },

    setAvatar_name(state, user) {
      state.user = user;
    },

    setBackground_image(state, user) {
      state.user = user;
    },

    setGameisFinished(state, game_is_finished) {
      state.game_is_finished = game_is_finished;
    },

    set_media_files(state, media_files) {
      // replace media_files urls with CDN urls
      // for all objects in media_files array, replace substring "https://filestoragesearch.blob.core.windows.net" in object.location with CDN_URL
      for (const file of media_files) {
        file.location = file.location.replace(
          "https://filestoragesearch.blob.core.windows.net",
          state.ice_mode ? CDN_URL_ICE : CDN_URL
        );
      }
      state.media_files = media_files;
    },
    // update personalization settings for current client
    updateClientConfig(state, config) {
      state.client_config = config.config;
      state.survey_id_state = config.survey_id;
      state.survey_id_int = config.survey_id_int;
      state.survey_name = config.survey_name;
      state.messenger_ui = config.messenger_ui;
      state.avatar_name = config.avatar_name;
      state.background_image = config.background_image;
      state.speed = config.speed;
      state.feedback_allowed = config.feedback_allowed;
      state.training_mode = config.training_mode;
    },

    //update error-handler show, title, messages
    updateError(state, error) {
      state.error_handler.show = true;
      if (
        error.response != undefined &&
        error.response.data != undefined &&
        error.response.data.msg != undefined
      ) {
        state.error_handler.title = error.response.data.msg;
        state.error_handler.msg =
          "Ein Fehler ist aufgetreten." + error.response.status;
      }
    },

    store_current_survey(state, surveyObject) {
      state.survey_data = surveyObject.dialog[0];
    },

    mut_switchAutoplayStatus(state, mode) {
      if (mode == "on") {
        state.audioAutoplay = true;
      } else {
        state.audioAutoplay = false;
      }
    },

    change_accent(state, color) {
      state.client_config.accent_color = color;
    },

    update_speed(state, speed) {
      state.speed = speed;
    },

    change_notification_sounds(state) {
      state.notifications_sounds = !state.notifications_sounds;
    },

    update_unread_messages(state) {
      state.unread_messages = !state.unread_messages;
    },

    change_server_busy_state(state, new_state) {
      if (new_state) {
        state.error_handler.show = true;
        state.error_handler.title = "server_busy";
        state.error_handler.msg =
          "Unser Messenger ist gerade ausergewöhnlich stark ausgelastet. Es kann kurz dauern bis wir mehr Ressourcen bereitgestellt haben. Sie können warten, oder versuchen es später erneut.";
      } else {
        state.error_handler.show = false;
        state.error_handler.title = "";
        state.error_handler.msg = "";
      }
    },

    set_survey_questions(state, payload) {
      state.dialog_blocks = payload;
    },

    update_surveydata_key(state, [key, value]) {
      state.survey_data[key] = value;
    },
  },

  actions: {
    set_ice_mode(context, mode) {
      context.commit("set_ice_mode", mode);
    },

    async getClientConfig(context, route_name_survey_id) {
      let route_name = route_name_survey_id[0];
      let survey_id = route_name_survey_id[1];

      if (
        ["Assess_live", "Dialog", "Multichat_live"].some((route) =>
          route_name.includes(route)
        )
      ) {
        let config = "";
        let survey_name = "";
        let messenger_ui = "";
        let avatar_name = "";
        let background_image = null;
        let speed = 0.5;
        let feedback_allowed = null;
        let training_mode = null;
        let survey_id_int = null;

        await axiosAuth_live
          .get(`/api/get_config?survey_id=${survey_id}`)
          .then((response) => {
            config = response.data["config"];
            survey_name = response.data["survey_name"];
            messenger_ui = response.data["messenger_ui"];
            avatar_name = response.data["avatar_name"];
            background_image = response.data["background_link"];
            speed = response.data["speed"];
            feedback_allowed = response.data["feedback_allowed"];
            training_mode = response.data["training_mode"];
            survey_id_int = response.data["survey_id_int"];

            context.commit("updateClientConfig", {
              config: config,
              survey_id: survey_id,
              survey_id_int: survey_id_int,
              survey_name: survey_name,
              messenger_ui: messenger_ui,
              avatar_name: avatar_name,
              background_image: background_image,
              speed: speed,
              feedback_allowed: feedback_allowed,
              training_mode: training_mode,
            });
          })
          .catch((error) => {
            this.error_show = true;
            if (
              error.response != undefined &&
              error.response.data != undefined &&
              error.response.data.msg != undefined
            ) {
              this.error_title = error.response.data.msg;
            } else {
              this.error_title = error.toString();
            }
            throw error;
          });
      } else {
        //assess/testen//live
        let config = "";
        let survey_name = "";
        let messenger_ui = "";
        let avatar_name = "";
        let background_image = null;
        let speed = 0.5;
        let survey_id_int = null;
        let feedback_allowed = null;
        let training_mode = null;

        await axiosAuth
          .get(`/api/get_config?survey_id=${survey_id}`)
          .then((response) => {
            config = response.data["config"];
            survey_name = response.data["survey_name"];
            messenger_ui = response.data["messenger_ui"];
            avatar_name = response.data["avatar_name"];
            background_image = response.data["background_link"];
            speed = response.data["speed"];
            feedback_allowed = response.data["feedback_allowed"];
            training_mode = response.data["training_mode"];
            survey_id_int = response.data["survey_id_int"];

            context.commit("updateClientConfig", {
              config: config,
              survey_id: survey_id,
              survey_id_int: survey_id_int,
              survey_name: survey_name,
              messenger_ui: messenger_ui,
              avatar_name: avatar_name,
              background_image: background_image,
              speed: speed,
              feedback_allowed: feedback_allowed,
              training_mode: training_mode,
            });
          })
          .catch((error) => {
            this.error_show = true;

            if (
              error.response != undefined &&
              error.response.data != undefined &&
              error.response.data.msg != undefined
            ) {
              this.error_title = error.response.data.msg;
            } else {
              this.error_title = error.toString();
            }
            throw error;
          });
      }
    },

    async setClientConfigFromLocal(context, payload) {
      context.commit("updateClientConfig", {
        config: payload.config,
        survey_id: payload.survey_id,
        survey_id_int: payload.survey_id_int,
        survey_name: payload.survey_name,
        messenger_ui: payload.messenger_ui,
        avatar_name: payload.avatar_name,
        background_image: payload.background_image,
        speed: payload.speed,
        feedback_allowed: payload.feedback_allowed,
        training_mode: payload.training_mode,
      });
      if (payload.media_files != undefined) {
        context.commit("set_media_files", payload.media_files);
      }
    },

    //trigger error-handler update
    error_alert(context, error) {
      context.commit("updateError", error);
    },

    //remove current config
    clear_config(context) {
      //config reset
      let nullConfig = {
        accent_color: null,
        logo_src: null,
        avatar_src: null,
        avatar_name: null,
        background_image: null,
      };
      context.commit("updateClientConfig", {
        config: nullConfig,
        survey_id: null,
        survey_id_int: null,
        survey_name: null,
        avatar_name: null,
        background_image: null,
        messenger_ui: null,
        speed: 0.5,
        feedback_allowed: null,
        training_mode: null,
      });
    },

    //update dashboard data on store
    async updateSurveyData(context, survey_data) {
      context.commit("store_current_survey", survey_data);
    },

    updateSurveyDataKey(context, [key, value]) {
      context.commit("update_surveydata_key", [key, value]);
    },

    switchAutoplayStatus(context, mode) {
      context.commit("mut_switchAutoplayStatus", mode);
    },

    change_accent_color(context, color) {
      context.commit("change_accent", color);
    },

    update_speed(context, speed) {
      context.commit("update_speed", speed);
    },

    change_notification_sounds(context) {
      context.commit("change_notification_sounds");
    },

    update_unread_messages(context) {
      context.commit("update_unread_messages");
    },

    update_media_files(context, media_files) {
      context.commit("set_media_files", media_files);
    },

    change_server_busy_state(context, new_state) {
      const serverBusyResetTime = 6000;
      context.commit("change_server_busy_state", new_state);
      // reset busy state after serverBusyResetTime seconds
      if (new_state) {
        setTimeout(() => {
          context.commit("change_server_busy_state", false);
        }, serverBusyResetTime);
      }
    },

    set_Game_is_finished(context, payload) {
      context.commit("setGameisFinished", payload);
    },

    set_survey_questions(context, payload) {
      context.commit("set_survey_questions", payload);
    },
  },

  modules: {
    auth,
  },
});
