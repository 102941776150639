import axios from "axios";
import store from "@/store";
import router from "@/router";

const pendingRequests = new Set();

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem("token")}`;
      },
    },
  },
});

const refresh = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem("refresh_token")}`;
      },
    },
  },
});

const retry = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem("token")}`;
      },
    },
  },
});

function getNewToken() {
  return new Promise((resolve, reject) => {
    refresh
      .post("/api/refresh")
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
        resolve(response.data.token);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

instance.interceptors.request.use((config) => {
  pendingRequests.add(config.url);
  return config;
});

instance.interceptors.response.use(
  function (response) {
    pendingRequests.delete(response.config.url);
    return response;
  },
  function (error) {
    pendingRequests.delete(error.config.url);
    if (error.response != undefined) {
      if (error.response.status == 403) {
        store.dispatch("auth/logout", error);
      } else if (error.response.status !== 401) {
        return Promise.reject(error);
      }
      // Logout user if token refresh didn't work or user is disabled
      else if (
        error.config.url == "/api/refresh" ||
        error.response.message == "Account is disabled."
      ) {
        store.dispatch("auth/logout");
        return Promise.reject(error);
      } else {
        // #get new token

        // Try request again with new token
        return getNewToken()
          .then((token) => {
            // New request with new token
            const config = error.config;
            config.headers["Authorization"] = `Bearer ` + token;

            return new Promise((resolve, reject) => {
              retry
                .request(config)
                .then((response) => {
                  resolve(response);
                })
                .catch((error) => {
                  // #here check if maybe a fresh token is required
                  if (
                    (error.response.status == 401) &
                      (error.response.data.msg == "Fresh token required") ||
                    (error.response.status == 401) &
                      (config.url == "/api/excel_export")
                  ) {
                    if (router.currentRoute.path != "/login_refresh") {
                      store.dispatch("auth/login_refresh", [
                        router.currentRoute.path,
                        config,
                      ]);
                    }

                    // store.dispatch("auth/login_refresh",error);
                  } else {
                    store.dispatch("auth/logout", error);
                    reject(error);
                  }
                });
            });
          })
          .catch((error) => {
            store.dispatch("auth/logout");
            return Promise.reject(error);
          });
      }
    }
  }
);

function isRequestPending(url) {
  return pendingRequests.has(url);
}

export { instance, isRequestPending };
